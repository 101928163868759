<template>
  <v-dialog v-model="dialog" width="70vw">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn fab small icon @click="openDialog" color="primary"
              ><v-icon small>mdi-dots-vertical</v-icon></v-btn
            >
          </span>
        </template>
        <span> Details </span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <v-card-title
        >{{ reportAssignment.clientName }} {{ reportAssignment.clientOsis }} ({{
          reportAssignment.formattedClientDob
        }})
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          Current School:
          <span class="font-weight-bold">{{ currentSchool?.schoolName }}</span>
        </div>
        <div>
          Team:
          <span class="font-weight-bold">{{ currentSchool?.team?.name }}</span>
        </div>
        <div>
          District:
          <span class="font-weight-bold">{{
            reportAssignment.districtId
          }}</span>
        </div>
        <v-spacer class="py-1"></v-spacer>
        <v-card outlined>
          <v-card-title class="text-subtitle-2">Current Plans</v-card-title>
          <v-card-text>
            <mandates-table :mandates="clientDetails.mandates"></mandates-table>
          </v-card-text>
        </v-card>
        <v-spacer class="py-1"></v-spacer>
        <v-card outlined>
          <v-card-title class="text-subtitle-2"
            >Recent Assignments</v-card-title
          >
          <v-card-text>
            <assignments-table
              :assignments="recentAssignments"
            ></assignments-table>
          </v-card-text>
        </v-card>
        <v-spacer class="py-1"></v-spacer>
        <v-card outlined>
          <v-card-title class="text-subtitle-2"
            >SEIT Report Assignments</v-card-title
          >
          <v-card-text>
            <client-report-assignments-table
              :reportAssignments="reportAssignments"
            ></client-report-assignments-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import MandatesTable from "./mandates-table.vue";
import AssignmentsTable from "./assignments-table.vue";
import ClientReportAssignmentsTable from "./client-report-assignments-table.vue";
export default {
  props: ["reportAssignment"],
  components: {
    MandatesTable,
    AssignmentsTable,
    ClientReportAssignmentsTable,
  },
  data() {
    return {
      dialog: false,
      clientDetails: {},
    };
  },
  computed: {
    currentSchool() {
      return this.clientDetails.client?.currentSchool;
    },
    recentAssignments() {
      // find assignments whose StartDate is after the earliest current mandate IEPPlanDate
      var startDate = this.clientDetails?.mandates?.reduce(
        (a, b) => (a.iepPlanDate < b.iepPlanDate ? a : b),
        []
      ).iepPlanDate;
      return this.clientDetails?.assignments?.filter(
        (x) => x.startDate >= startDate
      );
    },
    reportAssignments() {
      return this.$store.getters["reportsModule/getReportAssignmentsForClient"](
        this.reportAssignment.clientId
      );
    },
  },
  methods: {
    async openDialog() {
      this.clientDetails = await this.$store.dispatch(
        "reportsModule/getClientDetails",
        { clientId: this.reportAssignment.clientId }
      );
      this.dialog = true;
    },
  },
};
</script>
